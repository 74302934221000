import Swal from "sweetalert2";

export default {
  methods: {
    success( { title = '', message = this.$t("SWEET_ALERT.SUCCESS.MESSAGE") }){
      return Swal.fire({
        title,
        text: message,
        icon: 'success',
        confirmButtonText: this.$t("SWEET_ALERT.SUCCESS.CONFIRM_TEXT"),
      });
    },
    warning( { message = this.$t("SWEET_ALERT.WARNING.MESSAGE"), title = '' }){
      return Swal.fire({
        title,
        text: message,
        icon: 'warning',
        confirmButtonText: this.$t("SWEET_ALERT.WARNING.CONFIRM_TEXT"),
      });
    },
    error( { message = this.$t("SWEET_ALERT.ERROR.MESSAGE"), title = '' }){
      return Swal.fire({
        title,
        text: message,
        icon: 'error',
        confirmButtonText: this.$t("SWEET_ALERT.ERROR.CONFIRM_TEXT"),
      });
    },
  }
}
